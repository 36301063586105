import {parseUrlParams} from '../../../core/library/url';
import MediaSources from "../../media_sources";


const isLocal = window.location.hostname.indexOf('localhost') + 1 > 0;

export const arabic_books = {
    SHOW_LANGS: true,
    BASE_DOMAIN: '/',
    APP_CLASS: 'arbooks',
    PROJECT_NAME: 'arbooks',
    PROJECT_PREFIX: 'arbooks',
    LOCALE: "en",
    PREVIEW_DOMAIN: isLocal ? "http://localhost:8082" : "",
    PREVIEW_HASH: "AE30845FBF6EE09305710B753A9C264C",
    SOCKET: 'http://localhost:9001',

    LANGS: [
        {title: "English", domain: "http://localhost:3000"},
        {title: "Russian", domain: "http://ru.localhost:3000"}
    ],

    UPLOAD: {
        "path": isLocal ? "http://localhost:8086/api/backend/media" : "/api/backend/media"
    },

    // vmru config
    VIEWS: {
        documents: {
            "article": "default_view_vm",
            "author": "authors_view",
            "category": "category_view",
            "selection": "category_view",
            "tag": "tag_view",
            "genres": "tag_view",
            "trend": "trend_view",
            "page": "page_view",
            "show": "show_view",
            "newspaper": "newspaper_view",
            "rnewspaper": "rnewspaper_view",
            "schedule": "schedule_view",
            "arates": 'arates_view',
            "aviews": 'aviews_view',
            "mcat": 'anons_view',
            "polls": 'poll',
            "covid": "covid",
            "stop": "stopwords",
            "redirects": "RedirectView",
        },
        documents_add: {
            "article": "add_article_vm",
            "trend": "add_trend",
            "news": "add_news",
            "author": "add_author",
            "category": "add_category_rbth",
            "tag": "add_tag",
            "selection": "add_tag",
            "genres": "AddGenres",
            "mcat": "anons_add",
            "banner": "add_banner",
            "page": "add_page",
            "newspaper": "add_newspaper",
            "rnewspaper": "add_rnewspaper",
            "episode": "add_episode",
            "battle": "add_battle",
            "schedule": "add_schedule",
            "arates": "arate_add",
            "aviews": "aviews_add",
            "infographic": "infographics_add",
            "polls": "poll_add",
            "covid": "covid",
            "afisha": "afisha_vm",
            "regionmat": "region_material",
            "mgallery": "mgallery",
            "stories": "stories",
            "receipt": "receipt",
            "stt": "stt",
            "stop": "add_stopwords",
            "pcategories": "pcategory",
            "podcasts": "podcasts",
            "redirects": "Redirect",
            "book": "AddBook",
        }
    },

    MENU: [
        {
            'title': 'DASHBOARD',
            'path': '/',
            'component': 'dashboard_index',
            'icon': 'dashboard',
        },
        {
            'title': 'DOCUMENTS',
            'path': '/documents/:type',
            'component': 'default_document',
            'subroutes': [
                {
                    'path': '/documents/:type/:id',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/:type/:id/preview',
                    'component': 'preview',
                },
                {
                    'path': '/documents/:type/create',
                    'component': 'add_documents',
                },
                {
                    'path': '/archive',
                    'component': 'archive',
                },
                {
                    'path': '/documents/episode/:id/chat',
                    'component': 'episode_chat',
                },
                {
                    path: '/mediagallery',
                    component: 'media_gallery',
                },
                {
                    path: '/gallery_video',
                    component: 'media_gallery',
                }
            ],
            'icon': 'container',
            'submenu': [
                {
                    'title': 'BOOKS',
                    'path': '/documents/book',
                    'icon': 'bars',
                },

                {
                    title: 'MEDIA_GALLERY_LIST',
                    path: '/mediagallery',
                    icon: 'picture',
                },
            ]
        },
        {
            title: 'DATABASES',
            icon: 'database',
            submenu: [
                {
                    'title': 'GENRES',
                    'path': '/documents/genres',
                    'icon': 'tag',
                },
                {
                    'title': 'Authors',
                    'path': '/documents/author',
                    'icon': 'user',
                },
                {
                    'title': 'Selections',
                    'path': '/documents/selection',
                    'icon': 'tag',
                },
            ]
        },

        {
            'title': 'BANNERS',
            'path': '/documents/banner',
            'icon': 'layout',
        },
        {
            'title': 'WIDGETS',
            'path': '/widgets',
            'icon': 'appstore',
            'component': 'widget',
            'submenu': [
                {
                    'title': 'WTYPE_ALL',
                    'path': '/widgets',
                    'icon': 'appstore',
                    'component': 'widget',
                },
                {
                    'title': 'WTYPE_MAIN_PAGE',
                    'path': '/widgets/type/main',
                    'icon': 'appstore',
                },

            ],
            'subroutes': [
                {
                    'path': '/widgets/:id',
                    'component': 'widget_edit',
                },
                {
                    'path': '/widgets/type/:type',
                    'component': 'widget',
                },

            ]
        },

        {
            title: 'INSTRUMENTS',
            icon: 'code',
            submenu: [

                {
                    'title': 'PUSH',
                    'path': '/push',
                    'icon': 'notification',
                    'component': 'push',
                },

                {
                    'title': 'REDIRECTS',
                    'path': '/documents/redirects',
                    'icon': 'api',
                    'component': 'redirects'
                },
            ]
        },



        
      

        {
            'title': 'AUTHORS',
            'icon': 'team',
            'submenu': [
                {
                    'title': 'AUTHORS_LIST',
                    'icon': 'team',
                    'path': '/documents/author',
                },
            ]
        },
        {
            'title': 'PAGES',
            'path': '/documents/page',
            'icon': 'file-text',
        },
        {
            'title': 'USERS',
            'path': '/users',
            'icon': 'user',
            'subroutes': [
                {
                    'path': '/users/:id',
                    'component': 'users_edit',
                },
                {
                    'path': '/users/groups/add',
                    'component': 'add_group',
                },
                {
                    'path': '/users/groups/:id',
                    'component': 'add_group',
                }
            ],
            'submenu': [
                {
                    'title': 'USERS',
                    'path': '/users',
                    'icon': 'user',
                    'component': 'users_index',
                },
                {
                    'title': 'GROUPS',
                    'icon': 'idcard',
                    'path': '/users/groups',
                    'component': 'groups_index',
                },
                {
                    'title': 'PERMISSIONS',
                    'icon': 'idcard',
                    'path': '/users/permissions',
                    'component': 'permission_index',
                }
            ]
        },
    ],

    WORKFLOW: {
        SHOW_LINK: true,
        PREPARE_LINK: (item) => {
            switch (item.type.toLowerCase()) {
                case "podcasts":
                    return ``;
                case "pcategories":
                    return ``;
                case "news":
                    return `/news/${item.publicId}-${item.slug}`;
                case "meaning":
                    return `/opinion/${item.publicId}-${item.slug}`;
                case "episode":
                    return `/tv/${item.publicId}-${item.slug}`;
                case "gallery":
                    return `/photo/${item.publicId}-${item.slug}`;
                case "infographic":
                    return `/infographic/${item.publicId}-${item.slug}`;
                case "battle":
                    return `/battle/${item.publicId}-${item.slug}`;
                case "show":
                    return ``;
                case "newspaper":
                    return ``;
                case "rnewspaper":
                    return ``;
                case "schedule":
                    return ``;
                case "author":
                    return `/author/${item.publicId}-${item.slug}`;
                case "trend":
                    return `/topic/${item.publicId}`;
                case "category":
                    return `/${item.slug}`;
                case "tag":
                    return `/tag/${item.slug}`;
                case "page":
                    return `/static/${item.slug}`;
                default:
                    return `/${item.category ? item.category.slug : ""}/${item.publicId}-${item.slug}`;
            }
        },
        SHOW_SEARCH: true,
        STATUSES: ['DRAFT', 'READY', 'ARCHIVE', 'PUBLISH'],
        STATUSES_COLOR: {
            'DRAFT': 'gray',
            'READY': 'volcano',
            'ARCHIVE': 'purple',
            'PUBLISH': 'green',
        },
        DEFAULT: 'DRAFT',
        LOCK_FOR: [
            'infographic',
            'episode',
            'article',
            'news',
            'gallery',
            'meaning'
        ],
        WORKFLOW_FOR: [
            'book',
        ],
    },

    WIDGETS: {
        WTYPE: ['main'],
        TYPES: ['Book', "Author", "Selection", "Genre", "Banner"],
        VIEWS: [
            {name: 'Slider with description', key: 'slider_description'},
            {name: 'Default slider', key: 'default_slider'},
        ],
    },


    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: isLocal ? "http://localhost:8086/images" : "/archive/images",
            VIDEO: isLocal ? "http://localhost:8086/videos" : "/archive/videos",
            PDF: isLocal ? "https://files.vm.ru/pdf" : "/archive/pdf",
            AUDIO: isLocal ? "http://localhost:8086/stt/" : "/archove/stt/",
        },
        // available copyright sources
        SOURCES: MediaSources,
        // available upload types
        UPLOAD_AVAILABLE: ['jpg', 'gif', 'png', 'jpeg', 'mp4', 'mp3'],
        // available embedded sources
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be', `tvzvezda.ru`],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
            'tvzvezda.ru': (url) => {
                return (
                    `<iframe src="${url}" width="500" height="315" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            }
        },
        PREPARE: (path) => {
            if (path.indexOf('mp4/') + 1 > 0) return `https://video.vm.ru${path.replace('https://files.vm.ru', '')}`;
            return path;
        },
    },

};

export default arabic_books;
